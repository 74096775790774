import { configureStore } from '@reduxjs/toolkit'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2'
import storageSession from 'redux-persist/lib/storage/session'

import { rootReducer } from './reducer/rootReducer'
import { State } from './types'

const persistConfig = {
  key: 'hsp/app',
  storage: storageSession,
  stateReconciler: autoMergeLevel2,
}
const persistedReducer = persistReducer<ReturnType<typeof rootReducer>>(
  persistConfig,
  rootReducer,
)

export const createConfiguredStore = (reducer: any, preloadedState?: State) => {
  return configureStore({
    reducer,
    devTools: true,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  })
}

export const store = createConfiguredStore(persistedReducer)

export const persistor = persistStore(store)
