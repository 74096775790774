import { CatalogItemProps } from 'src/contexts/discovery'

export const hitKeyDataCatalog: (keyof CatalogItemProps)[] = [
  'author-display',
  'publisher-display',
  'publish-year-display',
  // 'editor-display',
]

export const filterTypes: Record<string, string> = {
  'catalog-publish-year-facet': 'range',
  'catalog-author-facet': 'list',
  'catalog-publisher-facet': 'list',
}
export const orderCatalogFacets = [
  'catalog-publish-year-facet',
  'catalog-publisher-facet',
  'catalog-author-facet',
]
